import { Injectable } from '@angular/core';
import { ClientContext } from '../models/clientContext';

@Injectable({
    providedIn: 'root'
})
export class ClientContextService {

    private clientContext: ClientContext;

    // Grab our setting keys for this iPart from the DOM
    constructor() {
        this.clientContext = <ClientContext>JSON.parse((document.querySelector("#__ClientContext") as HTMLInputElement).value);

    }

    public GetSelectedId(): string {
        return this.clientContext.selectedPartyId;
    }

    public GetBaseUrl(): string {
        return this.clientContext.baseUrl;
    }

    public GetWebSiteRoot(): string {
        return this.clientContext.websiteRoot;
    }

    public GetLoggedInId(): string {
        return this.clientContext.loggedInPartyId;
    }

    // Returns url id if clientContext id is not the same
    public GetMemberId(): string {
        const clientContextId = this.clientContext.selectedPartyId;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('ID')) {
            const urlId = urlParams.get('ID');
            return urlId !== clientContextId ? urlId : clientContextId;
        }
        return clientContextId;
    }
}
