import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ClientContextService } from '../../../../services/ClientContextService';

declare var jQuery: any;

@Component({
  selector: 'app-profile-redirect',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class ProfileRedirectAppComponent {
  title = 'ProfileRedirect';
  memberId;
  
  httpOptions = {
    headers: new HttpHeaders({
      RequestVerificationToken: (document.getElementById('__RequestVerificationToken') as HTMLInputElement).value
    })
  };

  constructor(private clientContext: ClientContextService, private http: HttpClient) {
    this.memberId = this.clientContext.GetMemberId();
  }

  ngOnInit(): void {

    if (this.memberId != undefined) {
      if (this.memberId == "3") {
        // anonymous, so don't do anything, unless on blank
        if (!window.location.href.includes('Sign_In.aspx')) {
          this.redirect();
        }
      } else {
        this.http.get(`api/iqa?QueryName=$/CCSMembers/Account_Page_Redirect&Parameter=${this.memberId}`, this.httpOptions).subscribe((data: any) => {

          if (data.Items.$values[0].Properties.$values[2].Value != undefined) {
            var memberType = data.Items.$values[0].Properties.$values[2].Value;
            var url = (window.location.href.includes('CCSMembersFR') ? '/CCSMembersFR/' : '/CCSMembers/') + memberType + '?ID=' + this.memberId;
            window.location.href = url;
          }
        });
      }
    } else {
      this.redirect();
    } 
  }

  redirect(): void {
    var url = window.location.href.includes('CCSMembersFR') ? '/CCSMembersFR/Sign_In.aspx' : '/CCSMembers/Sign_In.aspx';
    window.location.href = url;
  }
}
