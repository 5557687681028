import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ProfileRedirectAppComponent } from './app.component';
import {AsiCoreModule} from '@advsol/core';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    ProfileRedirectAppComponent
  ],
  imports: [
    BrowserModule,
      AsiCoreModule,
      HttpClientModule,
  ],
  providers: [],
    bootstrap: [ProfileRedirectAppComponent]
})
export class AppModule { }
